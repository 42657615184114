<template>
  <div>
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg fill="#000" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
          </svg>
        </a>
      </div>
      <div class="text-dark width-available text-center">
        <router-link to="/">
          <img style="float: left; width: 110px" src="/img/chopLifeLogo.png" class="top-header-logo img-fluid"
            alt="Chopbet Logo" />
        </router-link>
      </div>
    </section>

    <section class="p-2 terms">
      <div class="container">
        <h5>
          <strong>
            Terms and Conditions
          </strong>
        </h5>

        <ul>
          <h6> <strong> 1. Acceptance</strong></h6>
          <li>
            These Account Terms and Conditions (hereinafter - the "Terms") constitute a legally binding agreement
            between you, when you open an account and use it to play games on the choplife website (hereinafter - the
            "Website") on the one part, and the website owner, Good Games limited on the other part.
          </li>
          <li>
            These Terms apply to all services, content and information provided on the website by the company.
          </li>
          <li>
            By opening an account, you agree to be bound by these Terms in compliance with the applicable law. It is
            understood and presumed per se that by the fact of the Website use, the respective owner has fully read,
            understood and accepted these Terms. If you disagree with any part of the Terms, you may not access or use
            the website.
          </li>
          <li>
            By participating in any activity offered at website you agree that in case of winning a prize, you will
            allow your name and photo to be used for promotional purposes by the Company.
          </li>
          <li>
            The Company reserves the right to modify or amend these Terms at its sole discretion. Any revisions to these
            Terms will be posted on the homepage of our Website. If we make changes, we will notify you by revising the
            date at the top of these Terms.
          </li>
          <li>
            All new and/or revised provisions of the Terms take effect immediately and apply to your use of the Website
            from that date on.
          </li>

          <h6> <strong> 2. Definitions</strong></h6>
          <li>
            Applicable Law - laws of Sierra Leone applicable under these Terms to any relations between the user and the
            company.
          </li>
          <li>
            Company (also referred to as "we") - is an organization and legal entity incorporated under the laws of
            Sierra Leone to provide games and services to users of the website.
          </li>
          <li>
            Game - any game, carried out by the company on the website as part of its offer.
          </li>
          <li>
            Service - any service, carried out by the company on the website as part of its offer.
          </li>
          <li>
            User (also referred to as the "Player", "you", "your") - owner of the account on the website, that has not
            been terminated, suspended or closed.
          </li>
          <li>
            Registration - the procedure of setting up an Account on the Website.
          </li>
          <li>
            The term 'Website' shall mean the website maintained and owned by the company at Choplife Gaming including
            any subdomains or local domains.
          </li>

          <h6> <strong> 3. User Conduct</strong></h6>
          <li>
            The user agrees to use the website for personal use and to play games in accordance with applicable laws,
            these terms, and game rules.
          </li>
          <li>
            The user agrees not to provide false, inaccurate, incomplete, or misleading information to the website.
          </li>
          <li>
            The user agrees to keep their personal password secure and update account information. If you know your
            username registration details or password, you must promptly notify us at <a
              to="mailto:support@choplife.com" style="color: var(--red);">support@choplife.com</a> .
          </li>
          <li>
            Choplife reserves the right to ask for proof of age from any users and to suspend their accounts until
            satisfactory documentation is provided.
          </li>
          <li>
            The user agrees not to violate or assist any third party in violating these terms and/or any applicable
            laws, which may include, but are not limited to, international laws, national laws, and regulations, etc.
          </li>
          <li>
            We reserve the right to disable your account and password, whether chosen by you or allocated by us, at any
            time if, in our reasonable opinion, you have failed to comply with any of the provisions of these Terms.
          </li>

          <h6> <strong> 4. Limitation of Liability</strong></h6>
          <li>
            The games, services, and content on the website are provided by a number of suppliers without any
            guarantees, conditions, or warranties as to their accuracy, quality, and suitability for a particular
            purpose or need. We do not guarantee that our website is error-free, reliable, or will operate without
            interruption.
          </li>
          <li>
            If you are not satisfied with the conditions and/or quality of the services, you must stop using the
            website. Using the website shall mean that you have no claims against the company.
          </li>
          <li>
            The company may in its discretion provided it is acting reasonably, declare that a stake is defective. In
            these circumstances, all relevant entries, games, tickets and all relevant prizes won will be void.
          </li>
          <li>
            The company liability to players and/or winners shall be limited to the amount of the stake.
          </li>
          <li>
            Insofar as is permitted by law, the company will not under any circumstances be responsible or liable to
            compensate the Winner or accept any liability for any loss, damage, personal injury or death occurring as a
            result of receiving a Prize except in the event it is caused by the negligence of the company.
          </li>
        </ul>
      </div>
    </section>
  </div>

</template>

<script>

export default {
  name: 'terms',
  components: {},
  mounted: function () {
    //
  },
  methods: {
    //
  },

}
</script>

<style scoped>
.nav-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
  gap: 0.7rem;
}

.nav-item .icon {
  position: relative;
}

body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1,
h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
}

p {
  margin-bottom: 10px;
}

.definition-list {
  margin-top: 20px;
}

.definition-list dt {
  font-weight: bold;
}

.definition-list dd {
  margin-bottom: 10px;
}
</style>